import React from "react";
import { Helmet } from "react-helmet";
import TextAreaAutosize from "react-autosize-textarea";
import classNames from "classnames";
import PageTitle from "../components/pageTitle";

import { form } from "../styles/form.module.scss";
import { contact, contactForm } from "./contact.module.scss";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Fresh Brewed Design - Contact</title>
      </Helmet>
      <PageTitle>Contact</PageTitle>
      <div className={contact}>
        <p>
          Tell me about the problem you&apos;re solving and where you are in the
          process. I can take it from there!
        </p>
        <form
          className={classNames(contactForm, form)}
          name="contact"
          method="POST"
          data-netlify="true"
          action="/thanks/"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="name" placeholder="Your name" />
          <input type="email" name="email" placeholder="Your email" />
          <TextAreaAutosize
            name="message"
            placeholder="How you're changing the world"
          ></TextAreaAutosize>
          <button type="submit">Let&apos;s go!</button>
        </form>
      </div>
    </>
  );
}
